import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import LinkedinIcon from "../../../assets/linkedin-leadership.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const LeadershipTeamSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          Norhart Team
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Leadership Team
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          Great things are never achieved by just one person. It takes a cohesive well-oiled team working together in
          unison to create greatness. #TeamNorhart
        </h4>

        <div className="container mt-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            <div className="col mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/about/norhart-mike-kaeding.png"
                      alt="Mike Kaeding, Chief Executive Officer"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Leadership</small>
                  <span className="product-name">Mike Kaeding</span>
                  <div className="small m-t-xs">
                    Chief Executive Officer
                    <br />
                    <br />{" "}
                    {`A visionary whose optics are set on accelerated growth best describes Mike. He is on a mission to dominate the multifamily industry by delivering the best construction cost-to-value in the industry! Thinking outside the box, analyzing data, and innovative ideas are what Mike is all about. `}
                  </div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/mikekaeding/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/about/norhart-tim-libertini.png"
                      alt="Tim Libertini, Chief Financial Officer"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Leadership</small>
                  <span className="product-name">Tim Libertini</span>
                  <div className="small m-t-xs">
                    {" "}
                    Chief Finanical Officer
                    <br />
                    <br />{" "}
                    {`Tim is laser-focused on creating cost-to-value disruption and Hockey Stick Growth in the multifamily industry. Analyzing complex numbers and data is built into Tim's core DNA. `}
                  </div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/timothy-libertini-81701744/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/about/norhart-marie-dickover.png"
                      alt="Marie Dickover, Chief Development Officer"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Leadership</small>
                  <span className="product-name">Marie Dickover</span>
                  <div className="small m-t-xs">
                    Chief Development Officer
                    <br />
                    <br />{" "}
                    {`Marie is leading the charge on the ground to disrupt the multifamily industry one building at a time. Pushing the envelope and raising the bar is what Marie does. It's part of her DNA.`}
                  </div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/marie-dickover-178095225/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default LeadershipTeamSection
