import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import LeadershipTeamSection from "../components/static-sections/LeadershipTeamSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import SixBoxSection from "../components/dynamic-sections/SixBoxSection"
import GoogleMapSection from "../components/dynamic-sections/GoogleMapSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesNorhartIcon from "../../assets/value-norhart-logo.svg"
import ValuesRespectIcon from "../../assets/value-complete-respect.svg"
import ValuesResultsIcon from "../../assets/value-pursuit-results.svg"
import ValuesAttitudeIcon from "../../assets/value-attitude.svg"
import ValuesMindsetIcon from "../../assets/value-mindset.svg"
import ValuesReliableIcon from "../../assets/value-reliable.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const AboutPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/about/",
          name: "About Us | Norhart",
          image: `${config.siteMetadata.siteUrl}/about/norhart-about-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="About Us | Norhart"
      description="At Norhart great things are never achieved by just one person. It takes a cohesive well-oiled team working together in unison to create greatness. Mike Kaeding Chief Executive Officer, Tim Libertini Chief Financial Officer"
      keywords="Mike Kaeding, Tim Libertini "
      imageTwitter={`${config.siteMetadata.siteUrl}/about/norhart-about-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/about/norhart-about-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Leadership"
        subtitle="Meet The Norhart Team"
        imageTitle="Norhart About"
        image="/about/norhart-about-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Meet Norhart's Innovators"
        tagLine="We Are On A Mission To Disrupt Multifamily Housing"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <LeadershipTeamSection colorPalette={colorPalette} />

      <TwoImageSection
        icon={ApartmentIcon}
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        iconTitle="Norhart Roadmap"
        title="The Norhart Vision"
        subtitle={`Our "Prime Directive" is to build exceptional apartments that create a better way for people to live. We are hyper-focused on creating a best-in-class customer experience for our residents.`}
        imageTitleOne="Our Vision and Roadmap"
        imageOne="/about/norhart-about-vision.webp"
        imageOneText="Our 10-year roadmap simply is to be the largest smart apartment developer in the Greater Minneapolis/St. Paul area with a focus on lifestyle living, building community, and giving back."
        imageOneAlt="Norhart Vision and Roadmap"
        imageOnePill=""
        imageTitleTwo="Building Traction"
        imageTwoText="Norhart is bringing our vision to the ground by hiring top-notch employees, analyzing market data, becoming strong problem solvers, and defining processes to optimize our business."
        imageTwo="/about/norhart-about-traction.webp"
        imageTwoAlt="Norhart Traction"
        imageTwoPill=""
        pillActive={true}
        colorPalette={colorPalette}
      />

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxIconFive={ValuesMindsetIcon}
        boxIconFour={ValuesAttitudeIcon}
        boxIconOne={ValuesNorhartIcon}
        boxIconSix={ValuesReliableIcon}
        boxIconThree={ValuesResultsIcon}
        boxIconTwo={ValuesRespectIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="This Is The Way"
        boxDescriptionOne="At Norhart, we hire, work, and live by our values. Our values are what defines and drives our culture at Norhart."
        boxTitleTwo="Value"
        boxSubTitleTwo="Achieve Great Things"
        boxDescriptionTwo="We lead from the front, love our work, we were born to do it; we accomplish far more than we thought possible."
        boxTitleThree="Value"
        boxSubTitleThree="Improve Every Day"
        boxDescriptionThree="We question the status quo;  We believe work shouldn't be complicated. We relentlessly simplify and automate our processes."
        boxTitleFour="Value"
        boxSubTitleFour="Be A Genuine Human"
        boxDescriptionFour="We are kind, supportive, and humble. We share credit, admit our mistakes and learn from them. We are open, honest, and vulnerable."
        boxTitleFive="Value"
        boxSubTitleFive="Resident Experience Obsession"
        boxDescriptionFive="We fight to make every resident touchpoint memorable and frictionless. We care about every little detail and its impact."
        boxTitleSix="Value"
        boxSubTitleSix="Level Yourself Up"
        boxDescriptionSix="We want to be the best in the world at what we do. We want to be so much better than the competition that the fight isn't fair."
        buttonColor="#34a5ff"
        buttonName="View #NorhartCulture"
        buttonText="#FFFFFF"
        buttonUrl="/norhart-life/"
        colorPalette={colorPalette}
        icon={ValuesIcon}
        subtitle="Knowing your team and company have your back is a great feeling! We are continually expanding our reach and setting our standards exceptionally high to elevate our organization and our people."
        title="Culture of Values"
      />

      <GoogleMapSection
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart HQ"
        query="Forest Lake,+MN+Encore"
        subtitle="Welcome to Norhart's Worldwide Corporate Headquarters in Forest Lake, MN. Our global and local teams are hard at work helping us expand our presence in the Minneapolis/St.Paul area."
        title="Welcome to Norhart"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Career Opportunities"
        badgeTitle="Now Hiring"
        title="Join Our A-Team"
        subtitle="Built into Norhart's DNA is the obsession to improve our customer's lives. We love elevating our people's skills, creativity, and drive to build the exceptional."
        imageTitle="Norhart Technology"
        image="/careers/norhart-careers-join-our-team.png"
        buttonName="Learn More"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default AboutPage
