import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  colorPalette: ColorPalette
  icon: any
  iconTitle: string
  query: string
  subtitle: string
  title: string
}

/** const */
const GoogleMapSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.colorPalette.cardAccentTop} 30%, ${props.colorPalette.cardAccentBottom} 70%)`,
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="text-center pb3">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center"
          style={{ fontSize: "15px" }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-3 mb-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <h3 className="contentHeroTitle">{props.title}</h3>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.8" }}>
          {props.subtitle}
        </h2>
        <div className="ms-2 me-2">
          <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBpCLUhIOG4a1AUbROQAYB897yv8izdrJA&q=${props.query}`}
            title="Google Maps"
            width="100%"
            height="400"
            frameBorder="1"
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>
    </section>
  )
}

/** export */
export default GoogleMapSection
